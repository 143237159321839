import React from "react";
import { Link } from "react-router-dom";
import { useWindowWidth } from "../../breakpoints";
import { Blog } from "../../components/Blog";
import { Button } from "../../components/Button";
import { ColorTransparent } from "../../components/ColorTransparent";
import { Heading } from "../../components/Heading";
import { LeadIll } from "../../components/LeadIll";
import { Process } from "../../components/Process";
import { Recruite } from "../../components/Recruite";
import { SpTransparent } from "../../components/SpTransparent";
import { LogoWhite22 } from "../../icons/LogoWhite22";
import { LogoWhite9 } from "../../icons/LogoWhite9";
import { Triangle0218 } from "../../icons/Triangle0218";
import { Triangle027 } from "../../icons/Triangle027";
import "./style.css";

export const Home = () => {
  const screenWidth = useWindowWidth();

  return (
    <div
      className="home"
      style={{
        height: screenWidth >= 1280 ? "1117px" : undefined,
        minWidth:
          screenWidth < 600
            ? "320px"
            : screenWidth >= 600 && screenWidth < 1280
            ? "600px"
            : screenWidth >= 1280
            ? "1280px"
            : undefined,
      }}
    >
      {((screenWidth >= 600 && screenWidth < 1280) || screenWidth < 600) && (
        <>
          <SpTransparent
            className="header-3"
            groupClassName="header-2"
            menu3StyleOverrideClassName="SP-transparent-instance"
          />
          <div className="div-7">
            <div
              className="FV"
              style={{
                height: screenWidth < 600 ? "422px" : screenWidth >= 600 && screenWidth < 1280 ? "602px" : undefined,
                justifyContent: screenWidth < 600 ? "flex-end" : undefined,
              }}
            >
              <div
                className="message-wrapper"
                style={{
                  backgroundImage:
                    screenWidth < 600
                      ? "url(/img/fv-2-2x.png)"
                      : screenWidth >= 600 && screenWidth < 1280
                      ? "url(/img/fv-2-1.png)"
                      : undefined,
                  height: screenWidth < 600 ? "422px" : screenWidth >= 600 && screenWidth < 1280 ? "686px" : undefined,
                  marginBottom: screenWidth >= 600 && screenWidth < 1280 ? "-84.00px" : undefined,
                  width: screenWidth < 600 ? "320px" : screenWidth >= 600 && screenWidth < 1280 ? "600px" : undefined,
                }}
              >
                <img
                  className="message"
                  style={{
                    height:
                      screenWidth < 600 ? "140px" : screenWidth >= 600 && screenWidth < 1280 ? "310px" : undefined,
                    left: screenWidth < 600 ? "125px" : screenWidth >= 600 && screenWidth < 1280 ? "233px" : undefined,
                    top: screenWidth < 600 ? "113px" : screenWidth >= 600 && screenWidth < 1280 ? "120px" : undefined,
                    width: screenWidth < 600 ? "69px" : screenWidth >= 600 && screenWidth < 1280 ? "134px" : undefined,
                  }}
                  alt="Message"
                  src={
                    screenWidth < 600
                      ? "/img/message.png"
                      : screenWidth >= 600 && screenWidth < 1280
                      ? "/img/message-1.png"
                      : undefined
                  }
                />
              </div>
            </div>
            <div className="LEAD">
              <LeadIll className="lead-ill-instance" />
              <div className="lead-text">
                <div className="flexcontainer-4">
                  <p
                    className="text-2"
                    style={{
                      textAlign: screenWidth >= 600 && screenWidth < 1280 ? "center" : undefined,
                    }}
                  >
                    <span className="text-wrapper-50">
                      経験値の高い一人一人が、
                      <br />
                    </span>
                  </p>
                  <p
                    className="text-3"
                    style={{
                      textAlign: screenWidth >= 600 && screenWidth < 1280 ? "center" : undefined,
                    }}
                  >
                    <span className="text-wrapper-50">パズルのように組み合わさった集合知</span>
                  </p>
                </div>
                <div className="text-wrapper-51">
                  単純にすべてにおいてDX化を推進するのではなく、高いスキルを持ったスタッフがそれぞれの知識や経験を基に、お客様が抱えている課題や問題の“整理整頓”のお手伝いから始めます。課題解決のために必要なピースを見極め、様々な手法をご提案しながら、チームとして力を合わせてお客様と一緒にパズルの完成図を描いていきます。
                </div>
              </div>
            </div>
            <div className="FLOW">
              <Heading
                className="heading-4"
                divClassName="heading-5"
                divClassNameOverride="heading-3"
                one="SP"
                text="FLOW"
                text1="ご相談の流れ"
              />
              <div className="flexcontainer-5">
                <p className="text-4">
                  <span className="text-wrapper-52">
                    詳しいお話をお伺いした上で、御社のご要望に合わせてカスタマイズした最適なプランをご提案・お見積りをさせていただきます
                    <br />
                  </span>
                </p>
                <p className="text-4">
                  <span className="text-wrapper-52">
                    （お見積りまでは費用はかかりません） まずはお問い合わせフォームまたはお電話にてご相談内容をお聞かせください。
                  </span>
                </p>
              </div>
              {screenWidth < 600 && (
                <>
                  <div className="processe">
                    <div className="group-5">
                      <Process
                        className="process-instance"
                        divClassName="process-3"
                        frameClassName="process-2"
                        one="two"
                      />
                      <Triangle0218 className="triangle02-18" color="#BACEF6" />
                      <Process
                        className="process-4"
                        divClassName="process-3"
                        frameClassName="process-2"
                        image="/img/image-1.png"
                        one="two"
                        text="Process 02"
                        text1="オンライン面談"
                      />
                      <Triangle0218 className="triangle-02" color="#BACEF6" />
                      <Process
                        className="process-5"
                        divClassName="process-3"
                        frameClassName="process-2"
                        image="/img/image-1-1.png"
                        one="two"
                        text="Process 03"
                        text1="情報資料のご提供"
                      />
                    </div>
                    <div className="group-6">
                      <Triangle0218 className="triangle02-1" color="#BACEF6" />
                      <div className="process-6">
                        <div className="frame-60">
                          <img className="image-4" alt="Image" src="/img/image-1-2.png" />
                        </div>
                        <div className="frame-61">
                          <div className="process-7">Process 04</div>
                          <div className="flexcontainer-6">
                            <p className="text-i-6">
                              <span className="text-wrapper-50">
                                提案書・見積書
                                <br />
                              </span>
                            </p>
                            <p className="text-i-6">
                              <span className="text-wrapper-50">の提示</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <Triangle0218 className="triangle02-17" color="#BACEF6" />
                      <Process
                        className="process-8"
                        divClassName="process-3"
                        frameClassName="process-2"
                        image="/img/image-1-3.png"
                        one="two"
                        text="Process 05"
                        text1="契約締結・着手"
                      />
                    </div>
                  </div>
                  <Button
                    className="button-instance"
                    color="purple"
                    device="SP"
                    divClassName="button-2"
                    text="詳しく見る"
                  />
                </>
              )}

              {screenWidth >= 600 && screenWidth < 1280 && (
                <>
                  <Button
                    className="button-instance"
                    color="purple"
                    device="SP"
                    divClassName="button-2"
                    text="詳しく見る"
                    to="/aboutcontents-744"
                  />
                  <div className="processe-2">
                    <div className="frame-62">
                      <Process
                        className="instance-node-3"
                        frameClassNameOverride="process-9"
                        image="/img/image-1-4.png"
                        imageClassName="process-10"
                        one="tab"
                        text="Process 01"
                        text1="お問い合わせ"
                      />
                      <div className="yajirushi">
                        <Triangle027 className="triangle02-7" />
                      </div>
                      <Process
                        className="process-11"
                        frameClassNameOverride="process-9"
                        image="/img/image-1.png"
                        imageClassName="process-10"
                        one="tab"
                        text="Process 02"
                        text1="オンライン面談"
                      />
                      <div className="yajirushi">
                        <Triangle027 className="triangle02-7" />
                      </div>
                      <div className="process-12">
                        <div className="frame-63">
                          <img className="image-5" alt="Image" src="/img/image-1-1.png" />
                        </div>
                        <div className="frame-64">
                          <div className="process-7">Process 03</div>
                          <div className="flexcontainer-6">
                            <p className="text-i-7">
                              <span className="text-wrapper-53">
                                情報資料の
                                <br />
                              </span>
                            </p>
                            <p className="text-i-7">
                              <span className="text-wrapper-53">ご提供</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-65">
                      <div className="yajirushi">
                        <Triangle027 className="triangle02-7" />
                      </div>
                      <div className="process-12">
                        <div className="frame-63">
                          <img className="image-5" alt="Image" src="/img/image-1-2.png" />
                        </div>
                        <div className="frame-64">
                          <div className="process-7">Process 04</div>
                          <div className="flexcontainer-6">
                            <p className="text-i-7">
                              <span className="text-wrapper-53">
                                提案書・見積書
                                <br />
                              </span>
                            </p>
                            <p className="text-i-7">
                              <span className="text-wrapper-53">の提示</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="yajirushi">
                        <Triangle027 className="triangle02-7" />
                      </div>
                      <Process
                        className="process-11"
                        frameClassNameOverride="process-9"
                        image="/img/image-1-3.png"
                        imageClassName="process-10"
                        one="tab"
                        text="Process 05"
                        text1="契約締結・着手"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="BLOGS">
              <Heading className="heading-4" divClassName="heading-6" one="SP" text="BLOG" text1="ブログ" />
              <div className="div-8">
                <Blog
                  className={`${screenWidth < 600 && "class-40"} ${
                    screenWidth >= 600 && screenWidth < 1280 && "class-41"
                  }`}
                  divClassName="frame-68"
                  divClassNameOverride="frame-67"
                  frameClassName="BLOG-instance"
                  frameClassNameOverride="frame-66"
                  one="one"
                  vector="/img/vector-2.svg"
                />
                <Blog
                  className={`${screenWidth < 600 && "class-40"} ${
                    screenWidth >= 600 && screenWidth < 1280 && "class-41"
                  }`}
                  divClassName="frame-68"
                  divClassNameOverride="frame-67"
                  frameClassName="BLOG-instance"
                  frameClassNameOverride="frame-66"
                  one="one"
                  vector="/img/vector-2.svg"
                />
                <Blog
                  className={`${screenWidth < 600 && "class-40"} ${
                    screenWidth >= 600 && screenWidth < 1280 && "class-41"
                  }`}
                  divClassName="frame-68"
                  divClassNameOverride="frame-67"
                  frameClassName="BLOG-instance"
                  frameClassNameOverride="frame-66"
                  one="one"
                  vector="/img/vector-2.svg"
                />
              </div>
              <Button
                className="button-instance"
                color="purple"
                device="SP"
                divClassName="button-2"
                text="ブログ一覧へ"
              />
            </div>
            <div className="RECRUITES">
              <Heading
                className="heading-4"
                divClassName="heading-8"
                divClassNameOverride="heading-7"
                one="SP"
                text="RECRUIT"
                text1="採用情報"
              />
              <div className="flexcontainer-5">
                <p className="text-4">
                  <span className="text-wrapper-52">
                    経験を積み、幅広い対応ができるエンジニアになりたいという方。
                    <br />
                  </span>
                </p>
                <p className="text-4">
                  <span className="text-wrapper-52">
                    {" "}
                    自分の能力を発揮できる場を求めている方。デジタルパズルでは一緒に働く仲間を募集しています。
                  </span>
                </p>
              </div>
              <div className="div-8">
                <Recruite
                  className={`${screenWidth < 600 && "class-42"} ${
                    screenWidth >= 600 && screenWidth < 1280 && "class-43"
                  }`}
                  divClassName="frame-68"
                  frameClassName="frame-66"
                  frameDivClassName="frame-69"
                  frameElementClassName="RECRUITE-instance"
                  one="one"
                  vector={
                    screenWidth < 600
                      ? "/img/vector-5.svg"
                      : screenWidth >= 600 && screenWidth < 1280
                      ? "/img/vector-11.svg"
                      : undefined
                  }
                />
                <Recruite
                  className={`${screenWidth < 600 && "class-42"} ${
                    screenWidth >= 600 && screenWidth < 1280 && "class-43"
                  }`}
                  divClassName="frame-68"
                  frameClassName="frame-66"
                  frameDivClassName="frame-69"
                  frameElementClassName="RECRUITE-instance"
                  one="one"
                  vector={
                    screenWidth < 600
                      ? "/img/vector-5.svg"
                      : screenWidth >= 600 && screenWidth < 1280
                      ? "/img/vector-11.svg"
                      : undefined
                  }
                />
                <Recruite
                  className={`${screenWidth < 600 && "class-42"} ${
                    screenWidth >= 600 && screenWidth < 1280 && "class-43"
                  }`}
                  divClassName="frame-68"
                  frameClassName="frame-66"
                  frameDivClassName="frame-69"
                  frameElementClassName="RECRUITE-instance"
                  one="one"
                  vector={
                    screenWidth < 600
                      ? "/img/vector-5.svg"
                      : screenWidth >= 600 && screenWidth < 1280
                      ? "/img/vector-11.svg"
                      : undefined
                  }
                />
              </div>
              <Button
                className="button-instance"
                color="purple"
                device="SP"
                divClassName="button-2"
                text="採用一覧へ"
              />
            </div>
            <div className="ABOUTUS">
              <Heading
                className="heading-4"
                divClassName="heading-10"
                divClassNameOverride="heading-9"
                one="SP"
                text="ABOUT US"
                text1="企業情報"
              />
              <div
                className="frame-70"
                style={{
                  alignItems:
                    screenWidth < 600 ? "center" : screenWidth >= 600 && screenWidth < 1280 ? "flex-start" : undefined,
                  flexDirection: screenWidth < 600 ? "column" : undefined,
                  justifyContent: screenWidth >= 600 && screenWidth < 1280 ? "center" : undefined,
                }}
              >
                <Button
                  color="white-textgray"
                  device="SP"
                  divClassName="button-2"
                  frameClassName="button-3"
                  text="企業理念"
                />
                <Button
                  color="white-textgray"
                  device="SP"
                  divClassName="button-2"
                  frameClassName="button-3"
                  text="経営理念"
                />
              </div>
            </div>
            <div
              className="CONTACT-wrapper"
              style={{
                padding:
                  screenWidth < 600 ? "80px 30px" : screenWidth >= 600 && screenWidth < 1280 ? "40px 50px" : undefined,
              }}
            >
              <Link
                className="CONTACT-3"
                style={{
                  alignSelf: screenWidth < 600 ? "stretch" : undefined,
                  width: screenWidth < 600 ? "100%" : screenWidth >= 600 && screenWidth < 1280 ? "500px" : undefined,
                }}
                to="/contact-600"
              >
                <div
                  className="frame-71"
                  style={{
                    gap:
                      screenWidth < 600
                        ? "20px 74px"
                        : screenWidth >= 600 && screenWidth < 1280
                        ? "20px 20px"
                        : undefined,
                    left: screenWidth < 600 ? "-10px" : undefined,
                    padding:
                      screenWidth < 600
                        ? "25px 60px"
                        : screenWidth >= 600 && screenWidth < 1280
                        ? "40px 20px"
                        : undefined,
                    top: screenWidth < 600 ? "-5px" : screenWidth >= 600 && screenWidth < 1280 ? "14px" : undefined,
                    width: screenWidth < 600 ? "280px" : screenWidth >= 600 && screenWidth < 1280 ? "500px" : undefined,
                  }}
                >
                  <div
                    className="group-7"
                    style={{
                      marginLeft: screenWidth < 600 ? "-26.00px" : undefined,
                      marginRight: screenWidth < 600 ? "-30.00px" : undefined,
                    }}
                  >
                    <div className="text-wrapper-54">お問い合わせ</div>
                    <div className="text-wrapper-55">CONTACT</div>
                  </div>
                  <div
                    className="flexcontainer-i-wrapper"
                    style={{
                      marginLeft: screenWidth < 600 ? "-25.00px" : undefined,
                      marginRight: screenWidth < 600 ? "-27.00px" : undefined,
                    }}
                  >
                    <div className="flexcontainer-7">
                      <p className="text-i-8">
                        <span className="text-wrapper-56">
                          制作のご依頼・ご相談・ご質問など
                          <br />
                        </span>
                      </p>
                      <p className="text-i-8">
                        <span className="text-wrapper-56">お気軽にお問い合わせください。</span>
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <footer className="footer-2">
              <div
                className="frame-72"
                style={{
                  flexDirection: screenWidth < 600 ? "column" : undefined,
                  gap: screenWidth < 600 ? "54px" : undefined,
                  justifyContent: screenWidth >= 600 && screenWidth < 1280 ? "space-between" : undefined,
                }}
              >
                <div
                  className="frame-73"
                  style={{
                    alignSelf: screenWidth < 600 ? "stretch" : undefined,
                    flex: screenWidth < 600 ? "0 0 auto" : undefined,
                    width: screenWidth < 600 ? "100%" : screenWidth >= 600 && screenWidth < 1280 ? "205px" : undefined,
                  }}
                >
                  <LogoWhite22 className="logo-white-26" />
                  <div className="flexcontainer-5">
                    <p className="text-i-9">
                      <span className="text-wrapper-57">
                        千葉県千葉市美浜区中瀬一丁目６番
                      </span>
                    </p>
                    <p className="text-i-9">
                      <span className="text-wrapper-76">m BAY POINT 幕張 2509</span>
                    </p>
                  </div>
                </div>
                <div
                  className="frame-74"
                  style={{
                    alignSelf: screenWidth < 600 ? "stretch" : undefined,
                    display:
                      screenWidth < 600 ? "flex" : screenWidth >= 600 && screenWidth < 1280 ? "inline-flex" : undefined,
                    gap: screenWidth < 600 ? "40px" : screenWidth >= 600 && screenWidth < 1280 ? "30px" : undefined,
                    width: screenWidth < 600 ? "100%" : undefined,
                  }}
                >
                  <div className="frame-75">
                    <div className="frame-76">
                      <Link className="text-wrapper-58" to="/philosophy-320">
                        理念
                      </Link>
                      <div
                        className="text-wrapper-59"
                        style={{
                          fontFamily:
                            screenWidth < 600
                              ? "var(--text-bold-mini-font-family)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "'Noto Sans JP', Helvetica"
                              : undefined,
                          fontSize:
                            screenWidth < 600
                              ? "var(--text-bold-mini-font-size)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "11px"
                              : undefined,
                          fontStyle: screenWidth < 600 ? "var(--text-bold-mini-font-style)" : undefined,
                          fontWeight:
                            screenWidth < 600
                              ? "var(--text-bold-mini-font-weight)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "700"
                              : undefined,
                          letterSpacing:
                            screenWidth < 600
                              ? "var(--text-bold-mini-letter-spacing)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "0.55px"
                              : undefined,
                          lineHeight:
                            screenWidth < 600
                              ? "var(--text-bold-mini-line-height)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "20px"
                              : undefined,
                        }}
                      >
                        - 企業理念
                      </div>
                      <div
                        className="text-wrapper-60"
                        style={{
                          fontFamily:
                            screenWidth < 600
                              ? "var(--text-bold-mini-font-family)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "'Noto Sans JP', Helvetica"
                              : undefined,
                          fontSize:
                            screenWidth < 600
                              ? "var(--text-bold-mini-font-size)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "11px"
                              : undefined,
                          fontStyle: screenWidth < 600 ? "var(--text-bold-mini-font-style)" : undefined,
                          fontWeight:
                            screenWidth < 600
                              ? "var(--text-bold-mini-font-weight)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "700"
                              : undefined,
                          letterSpacing:
                            screenWidth < 600
                              ? "var(--text-bold-mini-letter-spacing)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "0.55px"
                              : undefined,
                          lineHeight:
                            screenWidth < 600
                              ? "var(--text-bold-mini-line-height)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "20px"
                              : undefined,
                        }}
                      >
                        {" "}
                        - 経営理念
                      </div>
                      <div
                        className="text-wrapper-61"
                        style={{
                          fontFamily:
                            screenWidth < 600
                              ? "var(--text-bold-mini-font-family)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "'Noto Sans JP', Helvetica"
                              : undefined,
                          fontSize:
                            screenWidth < 600
                              ? "var(--text-bold-mini-font-size)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "11px"
                              : undefined,
                          fontStyle: screenWidth < 600 ? "var(--text-bold-mini-font-style)" : undefined,
                          fontWeight:
                            screenWidth < 600
                              ? "var(--text-bold-mini-font-weight)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "700"
                              : undefined,
                          letterSpacing:
                            screenWidth < 600
                              ? "var(--text-bold-mini-letter-spacing)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "0.55px"
                              : undefined,
                          lineHeight:
                            screenWidth < 600
                              ? "var(--text-bold-mini-line-height)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "20px"
                              : undefined,
                        }}
                      >
                        {" "}
                        - 社訓
                      </div>
                    </div>
                    <div className="frame-76">
                      <Link className="text-wrapper-58" to="/profile-600">
                        会社概要
                      </Link>
                      <div
                        className="text-wrapper-62"
                        style={{
                          fontFamily:
                            screenWidth < 600
                              ? "var(--text-bold-mini-font-family)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "'Noto Sans JP', Helvetica"
                              : undefined,
                          fontSize:
                            screenWidth < 600
                              ? "var(--text-bold-mini-font-size)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "11px"
                              : undefined,
                          fontStyle: screenWidth < 600 ? "var(--text-bold-mini-font-style)" : undefined,
                          fontWeight:
                            screenWidth < 600
                              ? "var(--text-bold-mini-font-weight)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "700"
                              : undefined,
                          letterSpacing:
                            screenWidth < 600
                              ? "var(--text-bold-mini-letter-spacing)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "0.55px"
                              : undefined,
                          lineHeight:
                            screenWidth < 600
                              ? "var(--text-bold-mini-line-height)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "20px"
                              : undefined,
                        }}
                      >
                        - 代表挨拶
                      </div>
                      <div
                        className="text-wrapper-63"
                        style={{
                          fontFamily:
                            screenWidth < 600
                              ? "var(--text-bold-mini-font-family)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "'Noto Sans JP', Helvetica"
                              : undefined,
                          fontSize:
                            screenWidth < 600
                              ? "var(--text-bold-mini-font-size)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "11px"
                              : undefined,
                          fontStyle: screenWidth < 600 ? "var(--text-bold-mini-font-style)" : undefined,
                          fontWeight:
                            screenWidth < 600
                              ? "var(--text-bold-mini-font-weight)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "700"
                              : undefined,
                          letterSpacing:
                            screenWidth < 600
                              ? "var(--text-bold-mini-letter-spacing)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "0.55px"
                              : undefined,
                          lineHeight:
                            screenWidth < 600
                              ? "var(--text-bold-mini-line-height)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "20px"
                              : undefined,
                        }}
                      >
                        - 概要
                      </div>
                    </div>
                    <div className="frame-76">
                      <Link className="text-wrapper-58" to="/aboutcontents-744">
                        制作について
                      </Link>
                      <div
                        className="text-wrapper-64"
                        style={{
                          fontFamily:
                            screenWidth < 600
                              ? "var(--text-bold-mini-font-family)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "'Noto Sans JP', Helvetica"
                              : undefined,
                          fontSize:
                            screenWidth < 600
                              ? "var(--text-bold-mini-font-size)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "11px"
                              : undefined,
                          fontStyle: screenWidth < 600 ? "var(--text-bold-mini-font-style)" : undefined,
                          fontWeight:
                            screenWidth < 600
                              ? "var(--text-bold-mini-font-weight)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "700"
                              : undefined,
                          letterSpacing:
                            screenWidth < 600
                              ? "var(--text-bold-mini-letter-spacing)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "0.55px"
                              : undefined,
                          lineHeight:
                            screenWidth < 600
                              ? "var(--text-bold-mini-line-height)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "20px"
                              : undefined,
                        }}
                      >
                        - 業務内容
                      </div>
                      <div
                        className="text-wrapper-65"
                        style={{
                          fontFamily:
                            screenWidth < 600
                              ? "var(--text-bold-mini-font-family)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "'Noto Sans JP', Helvetica"
                              : undefined,
                          fontSize:
                            screenWidth < 600
                              ? "var(--text-bold-mini-font-size)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "11px"
                              : undefined,
                          fontStyle: screenWidth < 600 ? "var(--text-bold-mini-font-style)" : undefined,
                          fontWeight:
                            screenWidth < 600
                              ? "var(--text-bold-mini-font-weight)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "700"
                              : undefined,
                          letterSpacing:
                            screenWidth < 600
                              ? "var(--text-bold-mini-letter-spacing)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "0.55px"
                              : undefined,
                          lineHeight:
                            screenWidth < 600
                              ? "var(--text-bold-mini-line-height)"
                              : screenWidth >= 600 && screenWidth < 1280
                              ? "20px"
                              : undefined,
                        }}
                      >
                        - ご相談の流れ
                      </div>
                    </div>
                  </div>
                  <div
                    className="frame-77"
                    style={{
                      display:
                        screenWidth < 600
                          ? "flex"
                          : screenWidth >= 600 && screenWidth < 1280
                          ? "inline-flex"
                          : undefined,
                      flex: screenWidth < 600 ? "1" : screenWidth >= 600 && screenWidth < 1280 ? "0 0 auto" : undefined,
                      flexGrow: screenWidth < 600 ? "1" : undefined,
                    }}
                  >
                    <Link className="text-wrapper-58" to="/blogs-320">
                      ブログ
                    </Link>
                    <Link className="text-wrapper-66" to="/recruits-320">
                      採用情報
                    </Link>
                    <div className="text-wrapper-67">よくある質問</div>
                    <Link className="text-wrapper-66" to="/information-security-basic-policy-320">
                      情報セキュリティ基本方針
                    </Link>
                    <Link className="text-wrapper-66" to="/privacypolicy-320">
                      プライバシーポリシー
                    </Link>
                  </div>
                </div>
              </div>
              <div className="text-wrapper-68">©︎ Digital Puzzle Inc</div>
            </footer>
          </div>
        </>
      )}

      {screenWidth >= 1280 && (
        <>
          <ColorTransparent
            className="color-transparent-instance"
            groupClassName="header-2"
            menuStyleOverrideClassName="header-4"
          />
          <div className="div-9">
            <div className="frame-78">
              <div className="FV-2">
                <div className="group-8">
                  <img className="message-2" alt="Message" src="/img/message-2.png" />
                </div>
              </div>
              <div className="LEAD-2">
                <LeadIll />
                <div className="lead-text-2">
                  <div className="flexcontainer-8">
                    <p className="text-5">
                      <span className="text-wrapper-69">
                        経験値の高い一人一人が、
                        <br />
                      </span>
                    </p>
                    <p className="text-5">
                      <span className="text-wrapper-69">パズルのように組み合わさった集合知</span>
                    </p>
                  </div>
                  <div className="text-wrapper-70">
                    単純にすべてにおいてDX化を推進するのではなく、高いスキルを持ったスタッフがそれぞれの知識や経験を基に、お客様が抱えている課題や問題の“整理整頓”のお手伝いから始めます。課題解決のために必要なピースを見極め、様々な手法をご提案しながら、チームとして力を合わせてお客様と一緒にパズルの完成図を描いていきます。
                  </div>
                </div>
              </div>
            </div>
            <div className="FLOW-2">
              <Heading
                className="heading-4"
                divClassName="heading-11"
                divClassNameOverride="heading-12"
                one="one"
                text="FLOW"
                text1="ご相談の流れ"
              />
              <div className="flexcontainer-9">
                <p className="text-6">
                  <span className="text-wrapper-71">
                    詳しいお話をお伺いした上で、御社のご要望に合わせてカスタマイズした最適なプランを
                    <br />
                  </span>
                </p>
                <p className="text-6">
                  <span className="text-wrapper-71">
                    ご提案・お見積りをさせていただきます
                    <br />
                  </span>
                </p>
                <p className="text-6">
                  <span className="text-wrapper-71">
                    （お見積りまでは費用はかかりません） まずはお問い合わせフォームまたはお電話にてご相談内容をお聞かせください。
                  </span>
                </p>
              </div>
              <div className="processe-3">
                <div className="frame-79">
                  <Process
                    className="process-13"
                    frameClassNameOverride="process-14"
                    image="/img/image-1-4.png"
                    one="one"
                    text="Process 01"
                    text1="お問い合わせ"
                  />
                  <div className="triangle-wrapper">
                    <Triangle027 className="triangle02-5" />
                  </div>
                  <Process
                    className="process-15"
                    frameClassNameOverride="process-14"
                    image="/img/image-1.png"
                    one="one"
                    text="Process 02"
                    text1="オンライン面談"
                  />
                  <div className="triangle-wrapper">
                    <Triangle027 className="triangle02-5" />
                  </div>
                  <div className="process-16">
                    <div className="frame-80">
                      <img className="image-6" alt="Image" src="/img/image-1-1.png" />
                    </div>
                    <div className="frame-81">
                      <div className="process-17">Process 03</div>
                      <div className="flexcontainer-6">
                        <p className="text-i-10">
                          <span className="text-wrapper-72">
                            情報資料の
                            <br />
                          </span>
                        </p>
                        <p className="text-i-10">
                          <span className="text-wrapper-72">ご提供</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-79">
                  <div className="triangle-wrapper">
                    <Triangle027 className="triangle02-5" />
                  </div>
                  <div className="process-16">
                    <div className="frame-80">
                      <img className="image-6" alt="Image" src="/img/image-1-2.png" />
                    </div>
                    <div className="frame-81">
                      <div className="process-17">Process 04</div>
                      <div className="flexcontainer-6">
                        <p className="text-i-10">
                          <span className="text-wrapper-72">
                            提案書・見積書
                            <br />
                          </span>
                        </p>
                        <p className="text-i-10">
                          <span className="text-wrapper-72">の提示</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="triangle-wrapper">
                    <Triangle027 className="triangle02-5" />
                  </div>
                  <Process
                    className="process-15"
                    frameClassNameOverride="process-14"
                    image="/img/image-1-3.png"
                    one="one"
                    text="Process 05"
                    text1="契約締結・着手"
                  />
                </div>
              </div>
              <Button
                arrow38StyleOverrideClassName="button-4"
                className="instance-node-3"
                color="one"
                device="PC"
                text="詳しく見る"
              />
            </div>
            <div className="BLOGS-2">
              <Heading className="heading-4" divClassNameOverride="heading-13" one="one" text="BLOG" text1="ブログ" />
              <div className="div-10">
                <Blog className="frame-82" one="one" vector="/img/vector-14.svg" />
                <Blog className="frame-82" one="one" vector="/img/vector-14.svg" />
                <Blog className="frame-82" one="one" vector="/img/vector-14.svg" />
              </div>
              <Button
                arrow38StyleOverrideClassName="button-4"
                className="instance-node-3"
                color="one"
                device="PC"
                text="ブログ一覧へ"
                to="/blog-1200"
              />
            </div>
            <div className="RECRUITES-2">
              <Heading
                className="heading-4"
                divClassName="heading-3"
                divClassNameOverride="heading-14"
                one="one"
                text="RECRUIT"
                text1="採用情報"
              />
              <div className="flexcontainer-10">
                <p className="text-6">
                  <span className="text-wrapper-71">
                    経験を積み、幅広い対応ができるエンジニアになりたいという方。
                    <br />
                  </span>
                </p>
                <p className="text-6">
                  <span className="text-wrapper-71">
                    {" "}
                    自分の能力を発揮できる場を求めている方。デジタルパズルでは一緒に働く仲間を募集しています。
                  </span>
                </p>
              </div>
              <div className="div-10">
                <Recruite
                  className="frame-83"
                  frameDivClassName="frame-85"
                  frameElementClassName="frame-84"
                  one="one"
                  vector="/img/vector-17.svg"
                />
                <Recruite
                  className="frame-83"
                  frameDivClassName="frame-85"
                  frameElementClassName="frame-84"
                  one="one"
                  vector="/img/vector-17.svg"
                />
                <Recruite
                  className="frame-83"
                  frameDivClassName="frame-85"
                  frameElementClassName="frame-84"
                  one="one"
                  vector="/img/vector-17.svg"
                />
              </div>
              <Button
                arrow38StyleOverrideClassName="button-4"
                className="instance-node-3"
                color="one"
                device="PC"
                text="採用一覧へ"
              />
            </div>
            <div className="ABOUTUS-2">
              <Heading
                className="heading-4"
                divClassName="heading-15"
                divClassNameOverride="heading-10"
                one="one"
                text="ABOUT US"
                text1="企業情報"
              />
              <div className="frame-86">
                <Button
                  arrow38StyleOverrideClassName="button-6"
                  className="button-5"
                  color="white"
                  device="PC"
                  text="企業理念"
                />
                <Button
                  arrow38StyleOverrideClassName="button-4"
                  className="button-7"
                  color="white"
                  device="PC"
                  text="経営理念"
                />
              </div>
            </div>
            <div className="CONTACT-4">
              <Link className="CONTACT-5" to="/contact-1200">
                <div className="frame-87">
                  <div className="group-9">
                    <div className="overlap-group-4">
                      <div className="text-wrapper-73">お問い合わせ</div>
                      <div className="text-wrapper-74">CONTACT</div>
                    </div>
                  </div>
                  <div className="group-10">
                    <div className="flexcontainer-11">
                      <p className="text-i-11">
                        <span className="text-wrapper-75">
                          制作のご依頼・ご相談・ご質問など
                          <br />
                        </span>
                      </p>
                      <p className="text-i-11">
                        <span className="text-wrapper-75">お気軽にお問い合わせください。</span>
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <footer className="footer-3">
              <div className="frame-88">
                <div className="frame-89">
                  <LogoWhite9 className="logo-white-9" />
                  <div className="flexcontainer-10">
                    <p className="text-i-12">
                      <span className="text-wrapper-76">
                        千葉県千葉市美浜区中瀬一丁目６番
                      </span>
                    </p>
                    <p className="text-i-12">
                      <span className="text-wrapper-76">m BAY POINT 幕張 2509</span>
                    </p>
                  </div>
                </div>
                <div className="frame-90">
                  <div className="frame-91">
                    <div className="frame-92">
                      <div className="text-wrapper-77">理念</div>
                      <div className="text-wrapper-78">- 企業理念</div>
                      <div className="text-wrapper-78"> - 経営理念</div>
                      <div className="text-wrapper-78"> - 社訓</div>
                    </div>
                    <div className="frame-92">
                      <div className="text-wrapper-77">会社概要</div>
                      <div className="text-wrapper-78">- 代表挨拶</div>
                      <div className="text-wrapper-78">- 概要</div>
                    </div>
                    <div className="frame-92">
                      <div className="text-wrapper-77">制作について</div>
                      <div className="text-wrapper-78">- 業務内容</div>
                      <div className="text-wrapper-78">- ご相談の流れ</div>
                    </div>
                  </div>
                  <div className="frame-93">
                    <div className="text-wrapper-77">ブログ</div>
                    <div className="text-wrapper-79">採用情報</div>
                    <Link className="text-wrapper-80" to="/contact-1200">
                      よくある質問
                    </Link>
                    <div className="text-wrapper-79">情報セキュリティ基本方針</div>
                    <Link className="text-wrapper-80" to="/privacypolicy-1200">
                      プライバシーポリシー
                    </Link>
                  </div>
                </div>
              </div>
              <div className="text-wrapper-68">©︎ Digital Puzzle Inc</div>
            </footer>
          </div>
        </>
      )}
    </div>
  );
};
