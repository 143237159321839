import React from "react";
import { Frame } from "../../components/Frame";
import { LogoWhite } from "../../components/LogoWhite";
import { NoimageWrapper } from "../../components/NoimageWrapper";
import { Process } from "../../components/Process";
import { Noimage } from "../../icons/Noimage";
import "./style.css";

export const HomeWrapper = () => {
  return (
    <div className="HOME-wrapper">
      <div className="frame-492" />
      <div className="frame-493">
        <img className="rectangle-9" alt="Rectangle" src="/img/lead-ill.png" />
        <div className="frame-494">
          <div className="group-100">
            <div className="text-wrapper-646">経験値の高い一人一人が、パズルのように組み合わさった集合知</div>
          </div>
          <div className="group-101">
            <div className="text-wrapper-647">
              単純にすべてにおいてDX化を推進するのではなく、高いスキルを持ったスタッフがそれぞれの知識や経験を基に、お客様が抱えている課題や問題の“整理整頓”のお手伝いから始めます。課題解決のために必要なピースを見極め、様々な手法をご提案しながら、チームとして力を合わせてお客様と一緒にパズルの完成図を描いていきます。
            </div>
          </div>
        </div>
      </div>
      <div className="frame-495">
        <div className="component-18">
          <div className="text-wrapper-648">ご相談の流れ</div>
          <div className="TEXTEXT-15">FLOW</div>
        </div>
        <div className="group-102">
          <div className="flexcontainer-76">
            <p className="text-53">
              <span className="text-wrapper-649">
                詳しいお話をお伺いした上で、御社のご要望に合わせてカスタマイズした最適なプランを
                <br />
              </span>
            </p>
            <p className="text-53">
              <span className="text-wrapper-649">
                ご提案・お見積りをさせていただきます
                <br />
              </span>
            </p>
            <p className="text-53">
              <span className="text-wrapper-649">
                （お見積りまでは費用はかかりません） まずはお問い合わせフォームまたはお電話にてご相談内容をお聞かせください。
              </span>
            </p>
          </div>
        </div>
        <div className="frame-496">
          <Process
            className="frame-498"
            divClassName="frame-501"
            divClassNameOverride="frame-497"
            frameClassNameOverride="frame-499"
            image="/img/image-1-4.png"
            imageClassName="frame-500"
            one="one"
            text="Process 01"
            text1="お問い合わせ"
          />
          <Process
            className="frame-498"
            divClassName="frame-501"
            divClassNameOverride="frame-497"
            frameClassNameOverride="frame-499"
            image="/img/image-1-4.png"
            imageClassName="frame-500"
            one="one"
            text="Process 01"
            text1="お問い合わせ"
          />
          <Process
            className="frame-498"
            divClassName="frame-501"
            divClassNameOverride="frame-497"
            frameClassNameOverride="frame-499"
            image="/img/image-1-4.png"
            imageClassName="frame-500"
            one="one"
            text="Process 01"
            text1="お問い合わせ"
          />
          <Process
            className="frame-498"
            divClassName="frame-501"
            divClassNameOverride="frame-497"
            frameClassNameOverride="frame-499"
            image="/img/image-1-4.png"
            imageClassName="frame-500"
            one="one"
            text="Process 01"
            text1="お問い合わせ"
          />
          <Process
            className="frame-498"
            divClassName="frame-501"
            divClassNameOverride="frame-497"
            frameClassNameOverride="frame-499"
            image="/img/image-1-4.png"
            imageClassName="frame-500"
            one="one"
            text="Process 01"
            text1="お問い合わせ"
          />
        </div>
        <button className="button-19">
          <div className="frame-502">
            <div className="polygon-wrapper">
              <img className="polygon-11" alt="Polygon" src="/img/color.svg" />
            </div>
            <div className="text-wrapper-650">詳しく見る</div>
          </div>
        </button>
      </div>
      <div className="frame-508">
        <div className="component-18">
          <div className="text-wrapper-656">採用情報</div>
          <div className="TEXTEXT-17">RECRUIT</div>
        </div>
        <div className="flexcontainer-77">
          <p className="text-54">
            <span className="text-wrapper-657">
              経験を積み、幅広い対応ができるエンジニアになりたいという方。
              <br />
            </span>
          </p>
          <p className="text-54">
            <span className="text-wrapper-657">
              {" "}
              自分の能力を発揮できる場を求めている方。デジタルパズルでは一緒に働く仲間を募集しています。
            </span>
          </p>
        </div>
        <div className="frame-509">
          <div className="frame-property">
            <div className="frame-507">
              <Noimage className="noimage-17" />
            </div>
            <div className="frame-510">
              <Frame className="frame-511" one="one" />
              <div className="group-103">
                <div className="text-wrapper-658">2024/00/00</div>
              </div>
              <div className="frame-512" />
            </div>
            <img className="line-27" alt="Line" src="/img/line-1.svg" />
            <div className="text-wrapper-659">
              見出しテキストが入ります。見出しのテキストが入ります。見出しのテキストが入ります。
            </div>
          </div>
          <div className="frame-property">
            <div className="frame-507">
              <NoimageWrapper className="noimage-15" noimage="/img/noimage-5.svg" />
            </div>
            <div className="frame-510">
              <Frame className="frame-511" one="one" />
              <div className="group-103">
                <div className="text-wrapper-658">2024/00/00</div>
              </div>
              <div className="frame-512" />
            </div>
            <img className="line-27" alt="Line" src="/img/line-1.svg" />
            <div className="text-wrapper-659">
              見出しテキストが入ります。見出しのテキストが入ります。見出しのテキストが入ります。
            </div>
          </div>
          <div className="frame-property">
            <div className="frame-507">
              <Noimage className="noimage-17" />
            </div>
            <div className="frame-510">
              <Frame className="frame-511" one="one" />
              <div className="group-103">
                <div className="text-wrapper-658">2024/00/00</div>
              </div>
              <div className="frame-512" />
            </div>
            <img className="line-27" alt="Line" src="/img/line-1.svg" />
            <div className="text-wrapper-659">
              見出しテキストが入ります。見出しのテキストが入ります。見出しのテキストが入ります。
            </div>
          </div>
        </div>
        <button className="button-19">
          <div className="frame-502">
            <div className="polygon-wrapper">
              <img className="polygon-11" alt="Polygon" src="/img/color.svg" />
            </div>
            <div className="text-wrapper-660">募集職種一覧へ</div>
          </div>
        </button>
      </div>
      <div className="frame-513">
        <div className="component-18">
          <div className="text-wrapper-661">企業情報</div>
          <div className="TEXTEXT-18">ABOUT US</div>
        </div>
        <div className="frame-504">
          <div className="white-button-wrapper">
            <div className="white-button-2">
              <div className="text-wrapper-662">企業理念</div>
            </div>
          </div>
          <div className="white-button-wrapper">
            <div className="white-button-2">
              <div className="text-wrapper-662">企業理念</div>
            </div>
          </div>
        </div>
      </div>
      <div className="frame-514">
        <div className="group-104">
          <div className="frame-515">
            <div className="group-105">
              <div className="overlap-group-22">
                <div className="text-wrapper-663">お問い合わせ</div>
                <div className="text-wrapper-664">CONTACT</div>
              </div>
            </div>
            <div className="group-106">
              <div className="flexcontainer-78">
                <p className="text-55">
                  <span className="text-wrapper-657">
                    制作のご依頼・ご相談・ご質問など
                    <br />
                  </span>
                </p>
                <p className="text-55">
                  <span className="text-wrapper-657">お気軽にお問い合わせください。</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer-27">
        <div className="frame-516">
          <div className="frame-517">
            <LogoWhite logoWhite="/img/logo-white-4-2.svg" logoWhiteClassName="logo-white-36" />
            <div className="flexcontainer-77">
              <p className="text-56">
                <span className="text-wrapper-649">
                  千葉県千葉市美浜区中瀬一丁目６番
                </span>
              </p>
              <p className="text-56">
                <span className="text-wrapper-649">m BAY POINT 幕張 2509</span>
              </p>
            </div>
          </div>
          <div className="frame-518">
            <div className="frame-519">
              <p className="text-wrapper-665">
                理念
                <br />- 企業理念 <br />- 経営理念
                <br />- 社訓
              </p>
              <div className="text-wrapper-666">
                会社概要
                <br />- 代表挨拶 <br />- 概要
              </div>
              <div className="text-wrapper-666">
                制作について
                <br />- 業務内容
                <br />- ご相談の流れ
              </div>
            </div>
            <div className="frame-519">
              <div className="text-wrapper-667">ブログ</div>
              <div className="text-wrapper-668">採用情報</div>
              <div className="text-wrapper-668">よくある質問</div>
              <div className="text-wrapper-668">情報セキュリティ基本方針</div>
              <div className="text-wrapper-668">プライバシーポリシー</div>
            </div>
          </div>
        </div>
        <div className="group-107">
          <div className="text-wrapper-669">©︎ Digital Puzzle Inc</div>
        </div>
      </footer>
    </div>
  );
};
